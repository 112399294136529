import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from 'src/components/common';
import { ListPage } from 'src/components/post';
import type { PageProps } from 'gatsby';
import type { GetCategoryPostsQuery } from 'types/graphql-type';
import type { PageCategoryContextType } from 'types/custom-type';

type Props = PageProps<GetCategoryPostsQuery, PageCategoryContextType>;

export const query = graphql`
  query GetCategoryPosts($category: String!) {
    posts: allPrismicPost(
      limit: 100
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { uid: { eq: $category } } } }
        }
      }
    ) {
      ...PostList
    }
  }
`;

const Category: React.FC<Props> = ({
  pageContext: { category, categoryLabel },
  data: { posts },
  location: { pathname },
}: Props) => (
  <>
    <SEO
      title={`開催プログラム Category: ${categoryLabel}`}
      pathname={pathname}
    />
    <ListPage posts={posts} category={category} />
  </>
);

export default Category;
